import { PropsWithChildren } from 'react';

import { useInitializeGuest } from 'hooks/guest';
import { useProvideUser } from 'hooks/user';

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  useProvideUser();
  useInitializeGuest();
  return <>{children}</>;
};
