import { motion } from 'framer-motion';

import { theme } from 'constants/theme';

import { useNotifications } from 'hooks/notifications';

import { CloseIcon } from 'components/icons/CloseIcon';

import { useAnimation } from './Notifications.hooks';

export const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  const { variants } = useAnimation();

  return (
    <motion.div
      initial="hidden"
      animate="show"
      exit={'hidden'}
      className="absolute bottom-2 left-2 right-2 z-50 flex flex-col items-center md:right-auto"
    >
      {notifications.map(notification => (
        <motion.div
          key={notification.id}
          variants={variants.item}
          className={
            'text-md m-2 flex rounded-md bg-carnationRed px-4 py-3 text-white shadow'
          }
        >
          <div className="mr-3">{notification.text}</div>

          <button onClick={() => removeNotification(notification.id)}>
            <CloseIcon size={20} color={theme.colors.white} />
          </button>
        </motion.div>
      ))}
    </motion.div>
  );
};
