import { IUser, IUserProfile } from 'types/user';

export const getFullname = (profile: IUserProfile) => {
  const firstName = profile.firstName || '';
  const lastName = profile.lastName || '';

  if (!firstName && !lastName) {
    return 'Unknown';
  }

  return `${firstName} ${lastName}`.trim();
};

export const getInitials = (profile: IUserProfile) => {
  const letter1 = profile.firstName.split('')[0] || '';
  const letter2 = profile.lastName.split('')[0] || '';

  if (!letter1 && !letter2) {
    return 'N/A';
  }

  return `${letter1}${letter2}`.trim();
};

export const getIsUserInCompany = (user: IUser, companyId: string) => {
  return [companyId, '*'].includes(user.profile.companyId);
};
