export const enum Permissions {
  CREATE_STORY = 'CREATE_STORY',
  EDIT_COMPANY_STORY = 'EDIT_COMPANY_STORY',
  ACCESS_ADMIN_DASHBOARD = 'ACCESS_ADMIN_DASHBOARD',
}

export interface IUserProfile {
  companyId: string;
  firstName: string;
  lastName: string;
  departmentId?: string;
  avatar?: string;
  job?: string;
}

export interface IUserResponse {
  uid: string;
  email: string;
  picture?: string;
  profile: IUserProfile;
  permissions?: Permissions[];
}

export interface IUser extends IUserResponse {
  canCreateStory: boolean;
}
