import { useEffect } from 'react';

import Router from 'next/router';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { Workbox } from 'workbox-window';

import { isDev } from 'helpers/env';
import { getParamFromPath } from 'helpers/url';

export const useServiceWorker = () => {
  useEffect(() => {
    if (
      !isDev() &&
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      (window as any).workbox !== undefined
    ) {
      const wb = (window as any).workbox as Workbox;
      // add event listeners to handle any of PWA lifecycle event
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
      wb.addEventListener('installed', event => {
        console.log(`Event ${event.type} is triggered.`);
        console.log(event);
      });

      wb.addEventListener('controlling', event => {
        console.log(`Event ${event.type} is triggered.`);
        console.log(event);
      });

      wb.addEventListener('activated', event => {
        console.log(`Event ${event.type} is triggered.`);
        console.log(event);
      });

      wb.addEventListener('waiting', () => {
        wb.addEventListener('controlling', () => {
          window.location.reload();
        });

        // Send a message to the waiting service worker, instructing it to activate.
        wb.messageSkipWaiting();
      });

      // ISSUE - this is not working as expected, why?
      // I could only make message event listenser work when I manually add this listenser into sw.js file
      wb.addEventListener('message', event => {
        console.log(`Event ${event.type} is triggered.`);
        console.log(event);
      });

      // never forget to call register as auto register is turned off in next.config.js
      wb.register();
    }
  }, []);
};

export const useTheme = () => {
  const { asPath } = useRouter();

  const updateDarkMode = (prefersDarkMode: boolean) => {
    if (prefersDarkMode) {
      document.querySelector('html')?.classList.add('dark');
    } else {
      document.querySelector('html')?.classList.remove('dark');
    }
  };

  useEffect(() => {
    const bg = getParamFromPath(asPath, 'bg') || sessionStorage.getItem('bg');
    const body = document.querySelector('body');

    if (bg && body) {
      sessionStorage.setItem('bg', bg);
      body.style.backgroundColor = `#${bg}`;
    }
  });

  useEffect(() => {
    const theme =
      getParamFromPath(asPath, 'theme') || sessionStorage.getItem('theme');

    if (theme) {
      sessionStorage.setItem('theme', theme);
      return updateDarkMode(theme !== 'light');
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    updateDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener('change', () =>
      updateDarkMode(mediaQuery.matches),
    );
  }, [asPath]);
};

export const usePageLoader = () => {
  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());
  }, []);
};
