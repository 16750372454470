// TODO: remove
export const theme = {
  colors: {
    bigStoneBlue: '#1A2243',
    carnationRed: '#F76C6C',
    vividTangerine: '#FFA082',
    white: '#FFFFFF',
    alabasterGrey: '#FAFAFA',
    culturedPearlGrey: '#F6F6F6',
    whiteSmoke: '#F5F5F5',
    galleryGrey: '#F0F0F0',
    whisperGrey: '#EDEDED',
    silverGrey: '#CCCCCC',
    veryLightGrey: '#CBCBCB',
    dustyGrey: '#999999',
    grey: '#858585',
    boulderGrey: '#7B7B7B',
    eclipse: '#404040',
    mineShaftGrey: '#242424',
    darkMineShaftGrey: '#1A1A1A',
    codGrey: '#121212',
    black: '#000000',
    successGreen: '#29CB00',
    errorRed: '#E40010',
  },
};

export const gradient = 'linear-gradient(135deg, #F76C6C 0%, #FFA082 100%)';
