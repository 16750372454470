import { useCallback, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { ICompany } from 'types/company';
import { IUser, Permissions } from 'types/user';

import { fetchUser } from 'api/fetch/user';

import { getIsUserInCompany } from 'helpers/user';

const userState = atom<IUser | null>({
  key: 'userState',
  default: null,
});

const canCreateStoriesSelector = selector<boolean>({
  key: 'canUserCreateStory',
  get: ({ get }) => {
    const user = get(userState);
    if (!user) {
      return false;
    }
    return user.permissions?.includes(Permissions.CREATE_STORY) || false;
  },
});

export const useClearUser = () => {
  const [, setUser] = useRecoilState(userState);

  const clearUser = useCallback(() => {
    setUser(null);
  }, [setUser]);

  return { clearUser };
};

export const useFetchUser = () => {
  const { data, refetch } = useQuery<IUser>(['user'], fetchUser, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return { data, refetch };
};

export const useProvideUser = () => {
  const { data, refetch } = useFetchUser();
  const [_, setUserState] = useRecoilState(userState);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setUserState(data);
    }
  }, [data, setUserState]);
};

export const useUser = () => {
  const [user] = useRecoilState(userState);

  return { user };
};

export const useUserPermissions = (company: ICompany) => {
  const [user] = useRecoilState(userState);

  const isUserInCompany = !!user && getIsUserInCompany(user, company.id);
  const canCreateStories = useRecoilValue(canCreateStoriesSelector);

  return { canCreateStories: isUserInCompany && canCreateStories };
};
