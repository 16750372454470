import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import 'nprogress/nprogress.css';
import { RecoilRoot } from 'recoil';

import { UserProvider } from 'components/providers/UserProvider';
import { Notifications } from 'components/shared/Notifications';
import { ProgressDialog } from 'components/shared/ProgressDialog';

import '../styles/globals.css';
import { usePageLoader, useServiceWorker, useTheme } from './app.hooks';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  useServiceWorker();
  useTheme();
  usePageLoader();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />

        <link rel="apple-touch-icon" href="/favicon/acehub_blue-300x300.png" />

        <link
          rel="icon"
          href="/favicon/acehub_blue-150x150.png"
          sizes="32x32"
        />

        <link
          rel="icon"
          href="/favicon/acehub_blue-150x150.png"
          sizes="32x32"
        />

        <link
          rel="icon"
          href="/favicon/acehub_blue-300x300.png"
          sizes="192x192"
        />

        <meta name="application-name" content="acehub" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="acehub" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#242424" />
        <link rel="manifest" href="/manifest.json" />

        <meta
          key="description"
          name="description"
          content="The immersive storytelling platform for distributed and next-generation workforces."
        />

        <meta
          key="og:image"
          property="og:image"
          content={`https://${process.env.NEXT_PUBLIC_VERCEL_URL}/preview/preview-1663157550891.png`}
        />
      </Head>

      <Script
        id="hotjar"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3032609,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        }}
      />

      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Component {...pageProps} />
            <ProgressDialog />
          </UserProvider>

          <Notifications />
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}

export default MyApp;
