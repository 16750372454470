export const getParamFromPath = (path: string, name: string) => {
  const query = path.split('?')[1];

  if (!query) {
    return null;
  }

  const params = new URLSearchParams(query);

  if (params.has(name)) {
    return params.get(name);
  }

  return null;
};
